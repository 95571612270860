import * as Sentry from '@sentry/browser';
import { map, filter } from 'rxjs/operators';
import {
  Crate,
  inject,
  lazy,
} from '@piwikpro/platform';
import HttpCrate, { HttpClient, MaintenanceInterceptor, RateLimitInterceptor } from '@piwikpro/http-crate';
import LicenseKeeperCrate from '@piwikpro/license-keeper-crate';
import RouterCrate from '@piwikpro/router-crate';
import TranslationCrate from '@piwikpro/translation-crate';
import NotificationCrate from '@piwikpro/notification-crate';
import ConfirmationCrate from '@piwikpro/confirmation-crate';
import AuthCrate from '@piwikpro/auth-crate';
import GuidesCrate from '@piwikpro/guides-crate';
import TitleCrate from '@piwikpro/title-crate';
import productAnalytics from '@piwikpro/product-analytics';
import thunk from 'redux-thunk';
import PPMS from './PPMS';
import PPMSSnippetPanel from './PPMSSnippetPanel';

declare global {
  interface Window {
    dataLayer: any;
  }

  interface History {
    navigationMode: string
  }
}

@Crate({
  name: 'PPMSCrate',
  imports: [
    HttpCrate,
    LicenseKeeperCrate,
    TranslationCrate,
    NotificationCrate,
    ConfirmationCrate,
    RouterCrate,
    GuidesCrate,
    AuthCrate,
    lazy(
      'OrganizationCrate',
      (): Promise<any> => import(/* webpackChunkName: "organization" */ '@piwikpro/organization-crate'),
      { preloaded: true },
    ),
    lazy(
      'AdministrationCrate',
      (): Promise<any> => import(/* webpackChunkName: "administration" */ '@piwikpro/administration'),
    ),
    lazy(
      'UserPanelCrate',
      (): Promise<any> => import(/* webpackChunkName: "user-panel" */ '@piwikpro/user-panel'),
    ),
    lazy(
      'InfoCrate',
      (): Promise<any> => import(/* webpackChunkName: "info" */ '@piwikpro/info'),
    ),
    TitleCrate,
  ],
  registry: async (bind: any, isBound: any, config: any) => {
    const cacheBuster = await fetch(
      config.get('STATIC_CACHE_BUSTER_URL'),
    ).then(res => res.text());

    productAnalytics.getStream()
      .pipe(filter(e => /@platform/.test(e.type)))
      .pipe(map((e) => {
        switch (e.type) {
          case '@platform/command/users/create/success':
            return {
              type: e.type,
              payload: {
                user_id: e.payload.data.id,
                eml: e.payload.data.attributes.email,
                creation_date: e.payload.data.attributes.addedAt,
              },
            };
          default:
            return { type: e.type };
        }
      }))
      .subscribe((event: any) => {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: event.type,
            ...Object.keys(event.payload || {}).reduce((acc: any, next: any) => {
                acc[`payload_${next}`] = event.payload[next];

                return acc;
            }, {}),
        });
      });

    window.history.navigationMode = 'compatible';

    bind('cacheBuster').toConstantValue(cacheBuster);
    bind('productAnalytics').toConstantValue(productAnalytics);
  },
  reduxMiddleware: [
    thunk,
    productAnalytics.createReduxMiddleware(),
  ],
  bootstrap: PPMS,
  panels: [PPMSSnippetPanel],
})
export class PPMSCrate {
  constructor(
    @inject('HttpCrate.httpClient') private httpClient: HttpClient,
    @inject('AuthCrate.interceptors.Auth') private authInterceptor: any,
    @inject('AuthCrate.interceptors.JWTRevalidator') private jwtRevalidatorInterceptor: any,
    @inject('HttpCrate.interceptors.MaintenanceInterceptor') private maintenanceInterceptor: MaintenanceInterceptor,
    @inject('HttpCrate.interceptors.RateLimitInterceptor') private rateLimitInterceptor: RateLimitInterceptor,
  ) {}

  static async configure(config: any) {
    await config.load('/user-panel/config.json');
    const sentryDsn = config.get('SENTRY_DSN');

    if (sentryDsn) {
      Sentry.init({
        dsn: sentryDsn,
        release: config.get('PPAS_VERSION'),
        environment: config.get('SENTRY_ENVIRONMENT'),
      });
      Sentry.setTag('logger', 'ppas-frontend');
    }
  }

  async onInit() {
    this.httpClient.addInterceptors([
      this.authInterceptor,
      this.maintenanceInterceptor,
      this.jwtRevalidatorInterceptor,
      this.rateLimitInterceptor,
    ]);
  }
}
